import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";

const Addtransaction = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [submittedData, setSubmittedData] = useState([]);
  const storedUserId = localStorage.getItem("user_id");
  const [userlogin, setUserlogin] = useState(false); // Set default to false for loading state

  // Handle form submission
  const onSubmit = async (data) => {
    setUserlogin(true); // Set loading state

    // Append new data to the list
    setSubmittedData([...submittedData, data]);

    // Set up FormData for file upload
    const formData = new FormData();
    formData.append("utr_no", data.transactionId);
    formData.append("amount", data.amount);
    formData.append("image", data.image[0]); // Get the first file from the FileList

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/add/upi/transaction",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            user_id: storedUserId, // Pass the user_id in headers
          },
        }
      );

      if (response.data === true) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error uploading transaction data:", error);
    } finally {
      setUserlogin(false); // Reset loading state
      reset(); // Reset the form fields
    }
  };

  return (
    <div className="container-fluid myprofile py-5">
      <h4 className=" mb-4 form-label text-white ">Transaction ID </h4>


      {/* Form for data entry */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label className="form-label ">Transaction ID</label>
          <input
            type="text"
            {...register("transactionId", {
              required: "Transaction ID is required",
            })}
            className="form-control"
            placeholder="Enter transaction ID"
          />
          {errors.transactionId && (
            <p className="text-danger">{errors.transactionId.message}</p>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Amount</label>
          <input
            type="number"
            {...register("amount", {
              required: "Amount is required",
              min: { value: 1, message: "Amount must be at least 1" },
              validate: (value) =>
                value > 0 || "Amount must be greater than zero",
            })}
            className="form-control"
            placeholder="Enter amount"
          />
          {errors.amount && (
            <p className="text-danger">{errors.amount.message}</p>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Upload Image</label>
          <input
            type="file"
            {...register("image", { required: "Image is required" })}
            className="form-control"
            accept="image/*"
          />
          {errors.image && (
            <p className="text-danger">{errors.image.message}</p>
          )}
        </div>

        <Button type="submit" variant="primary" disabled={userlogin}>
          {userlogin ? (
            <>
              <ThreeDots color="#00BFFF" height={20} width={50} />
              Updating...
            </>
          ) : (
            "Add"
          )}
        </Button>
      </form>
    </div>
  );
};

export default Addtransaction;
