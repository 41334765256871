import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // If you're using location
import moment from "moment";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { Button } from "react-bootstrap";

const Activation = () => {
  // Retrieve the user_id from localStorage
  const storedUserId = localStorage.getItem("user_id");

  // State for user history
  const [userhistory, setUserhistory] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for better UX
  const [error, setError] = useState(null); // Error state

  // Fetch user withdrawal history on component mount
  useEffect(() => {
    fetchUserHistory();
  }, []);

  const [userlogin, setUserlogin] = useState(null);

  const fetchUserHistory = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/activation/list",
        { u_id: storedUserId }, // Send the appropriate data if necessary in the request body
        {
          headers: {
            user_id: storedUserId, // Pass the user_id in headers
          },
        }
      );

      // Set the fetched data in state
      setUserhistory(response.data.list);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      setLoading(false); // Set loading to false even on error
      setError("Error fetching data. Please try again."); // Set error message
      console.error("Error fetching user history:", error);
    }
  };

  const Activation = async (u_id) => {
    setUserlogin(u_id);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/status-update/activation",

        { user_id: u_id, status: "active" }, // Send the appropriate data if necessary in the request body
        {
          headers: {
            u_id: storedUserId, // Pass the user_id in headers
          },
        }
      );

      if (response.data.result === true) {
        alert("Activation Successful");
        fetchUserHistory();
      } else alert(response.data.message);
    } catch (error) {
      console.error("Error activating user:", error);
    } finally {
      setUserlogin(null);
    }
  };

  return (
    <>
      <div className="container-fluid myprofile py-5">
        {loading ? (
          <div className="mt-5">
            {" "}
            <ThreeDots color="#00BFFF" height={20} width={50} />
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <>
            <div>
              <div className="addwallet">
                <h4 className="text-white ">Activation List </h4>
              </div>
            </div>

            <div className="table-responsive mt-4">
              <table className="table table-light table-hover shadow">
                <thead>
                  <tr>
                    <th scope="col">Sl no</th>
                    <th scope="col">Name </th>
                    <th scope="col">Referal Id </th>

                    <th scope="col">Date</th>

                    <th scope="col">Activation</th>
                  </tr>
                </thead>
                <tbody>
                  {userhistory?.length > 0 ? (
                    userhistory?.map((details, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{details.u_first_name}</td>
                        <td>{details.u_referal_id}</td>

                        <td>
                          {moment(details.u_joining_date).format("DD MMM YYYY")}
                        </td>
                        <td className="text-capitalize">
                          <Button
                            variant="primary"
                            disabled={userlogin === details.u_id} // Disable only the clicked button
                            onClick={() => Activation(details.u_id)}
                          >
                            {userlogin === details.u_id ? (
                              <>
                                <ThreeDots
                                  color="#00BFFF"
                                  height={20}
                                  width={50}
                                />
                                Activating...
                              </>
                            ) : (
                              <div className="text-capitalize">
                                {details.u_status}
                              </div>
                            )}
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Activation;
